<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
	<div
		:class="
			cn(
				'flex flex-row-reverse gap-xl px-3xl pt-3xl border-t border-gray-200',
				props.class,
			)
		"
	>
		<slot />
	</div>
</template>
