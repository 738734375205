<script setup lang="ts">
import {
	Button,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Sheet,
	SheetContent,
	SheetFooter,
	SheetTrigger,
} from '@laam/ui/base';
import Selector from './Selector.vue';
import DeliveryOptionsTrigger from './DeliveryOptionsTrigger.vue';
import { useCountryCode } from '~/stores/country';
import { useCurrency } from '~/stores/currency';
import ProvinceSelector from './ProvinceSelector.vue';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';
import DeliveryOptionsTooltip from './DeliveryOptionsTooltip.vue';
import { countriesCurrencyMap } from '~/utils/enums';

const { $eventClient } = useNuxtApp();
const allowedProvinces = ['US', 'CA', 'AU'];

const CurrencyStore = useCurrency();
const { selectedCurrency } = storeToRefs(CurrencyStore);
const { setCurrency } = CurrencyStore;

const CountryStore = useCountryCode();
const { deliverToCountry, deliverToProvince, countryCode } =
	storeToRefs(CountryStore);
const { setDeliverToCountry, setDeliverToProvince } = CountryStore;
const schema = toTypedSchema(
	z
		.object({
			updateCountryTo: z.string(),
			updateProvinceTo: z.string().optional(),
			updateCurrencyTo: z.string(),
		})
		.refine(
			(data) => {
				if (allowedProvinces.includes(data.updateCountryTo)) {
					if (
						data.updateProvinceTo === undefined ||
						data.updateProvinceTo === ''
					) {
						return false;
					}
					return true;
				}
				return true;
			},
			{
				message: 'Please select State/province.',
				path: ['updateProvinceTo'],
			},
		),
);
const { values, handleSubmit, setValues, setFieldValue } = useForm({
	validationSchema: schema,
	keepValuesOnUnmount: true,
	initialValues: {
		updateCountryTo: deliverToCountry.value,
		updateProvinceTo: deliverToProvince.value,
		updateCurrencyTo: selectedCurrency.value,
	},
});

const open = ref(false);

const provinceAllowed = computed(() => {
	return allowedProvinces.includes(values.updateCountryTo || '');
});

const saveChanges = () => {
	setCurrency(values.updateCurrencyTo as keyof typeof CURRENCIES);
	setDeliverToCountry(values.updateCountryTo!);
	setDeliverToProvince(values.updateProvinceTo!);
	$eventClient.sendEvent('delivery-options-saved', {
		country: values.updateCountryTo!,
		province: values.updateProvinceTo || '',
		currency: values.updateCurrencyTo!,
		defaultCountry: countryCode.value,
	});
	open.value = false;
};

const setDefaultValue = () => {
	setValues({
		updateCurrencyTo: selectedCurrency.value,
		updateCountryTo: deliverToCountry.value,
		updateProvinceTo: deliverToProvince.value,
	});
};

watch(open, (newValue, oldValue) => {
	if (!newValue && oldValue) {
		setDefaultValue();
	}
});
const onSubmit = async () => {
	handleSubmit(() => saveChanges())();
};
watch(
	() => values.updateCountryTo,
	() => {
		setFieldValue('updateProvinceTo', '', false);
		// if country is changed, set currency to native currency
		setFieldValue(
			'updateCurrencyTo',
			countriesCurrencyMap[values.updateCountryTo!] ?? 'usd',
			false,
		);
	},
);
</script>
<template>
	<DeliveryOptionsTooltip />
	<form id="deliveryOptionsForm" @submit.prevent="onSubmit">
		<Sheet
			v-if="isMobileView()"
			v-model:open="open"
			@update:open="open != open"
		>
			<SheetTrigger :as-child="true" @click="open = true">
				<DeliveryOptionsTrigger :open="open" />
			</SheetTrigger>
			<SheetContent
				side="bottom"
				class="w-full rounded-t-xl pb-[82px]"
				:show-close-icon="false"
			>
				<div class="flex p-3xl flex-col gap-3xl">
					<!-- country dropdown -->
					<div class="flex flex-col gap-md">
						<div class="flex flex-col gap-sm">
							<p class="text-gray-700 text-sm font-semibold">Deliver to</p>
							<Selector type="country" width="352" />
						</div>
						<ProvinceSelector v-if="provinceAllowed" class="mt-xxs" />
					</div>
					<div class="flex flex-col gap-sm">
						<p class="text-gray-700 text-sm font-semibold">Show prices in</p>
						<Selector type="currency" width="352" />
					</div>
				</div>
				<SheetFooter class="fixed bottom-none w-full pb-3xl">
					<Button
						size="xl"
						class="w-full h-10xl"
						type="submit"
						@click="onSubmit"
					>
						Save
					</Button>
				</SheetFooter>
			</SheetContent>
		</Sheet>
		<Popover v-else v-model:open="open" @update:open="open != open">
			<PopoverTrigger
				:as-child="true"
				data-testid="delivery-option-popover"
				@click="open = true"
			>
				<DeliveryOptionsTrigger :open="open" />
			</PopoverTrigger>
			<PopoverContent class="w-[390px]">
				<div
					class="flex p-md justify-center items-center gap-3xl border-b border-gray-200"
				>
					<p class="text-gray-900 text-center text-lg font-semibold">
						Choose your location
					</p>
				</div>
				<div class="flex p-3xl flex-col gap-3xl">
					<!-- country dropdown -->
					<div class="flex flex-col gap-md">
						<div class="flex flex-col gap-sm">
							<p class="text-gray-700 text-sm font-semibold">Deliver to</p>
							<Selector
								type="country"
								data-testid="country-selector-trigger"
								width="352"
							/>
						</div>
						<ProvinceSelector v-if="provinceAllowed" class="mt-xxs" />
					</div>
					<div class="flex flex-col gap-sm">
						<p class="text-gray-700 text-sm font-semibold">Show prices in</p>
						<Selector type="currency" width="352" />
					</div>
				</div>
				<div class="flex p-3xl gap-3xl border-t border-gray-200 w-full">
					<Button
						size="lg"
						class="w-full h-10xl"
						type="submit"
						data-testid="delivery-option-save-button"
						@click="onSubmit"
					>
						Save
					</Button>
				</div>
			</PopoverContent>
		</Popover>
	</form>
</template>
