<script setup lang="ts">
import { useCurrency } from '~/stores/currency';
import CountryIcon from './CountryIcon.vue';
import { useCountryCode } from '~/stores/country';
import { Button } from '@laam/ui/base';
import { PhCaretDown, PhCaretUp } from '@phosphor-icons/vue';
const { deliverToCountry } = storeToRefs(useCountryCode());
const { selectedCurrency } = storeToRefs(useCurrency());
interface DeliveryOptionsTriggerProps {
	open: boolean;
}
defineProps<DeliveryOptionsTriggerProps>();
</script>
<template>
	<div
		v-if="isMobileView()"
		class="flex gap-md justify-center items-center py-lg pl-md pr-0"
	>
		<CountryIcon :code="deliverToCountry" />
		<div class="!min-w-[1px] !w-[1px] h-5xl rounded-[4px] bg-gray-700" />
		<p class="text-gray-700 text-md font-medium">
			{{ selectedCurrency.toUpperCase() }}
		</p>
	</div>
	<Button v-else variant="tertiary">
		<div class="flex flex-col w-full">
			<p class="text-gray-500 text-xs">Deliver to / Currency</p>
			<div class="flex items-center gap-md">
				<CountryIcon :code="deliverToCountry" />
				<div class="flex items-center gap-xs">
					<p class="text-gray-900 text-sm font-semibold">
						{{ deliverToCountry }} / {{ selectedCurrency.toUpperCase() }}
					</p>
					<PhCaretDown v-if="!open" />
					<PhCaretUp v-else />
				</div>
			</div>
		</div>
	</Button>
</template>
