<script setup lang="ts">
import { Input, FormField, FormItem, FormControl } from '@laam/ui/base';
import { PhCheck, PhMagnifyingGlass } from '@phosphor-icons/vue';
import { useFieldValue } from 'vee-validate';
interface ProvinceSelectorBody {
	provinces: {
		name: string;
		code: string;
	}[];
	fieldName: string;
	class?: string;
}
const props = defineProps<ProvinceSelectorBody>();
const provinceCode = useFieldValue(props.fieldName) as Ref<string>;

const searchQuery = ref('');
const currentAlphabet = ref('');
const provinceRefs = ref<Record<string, HTMLElement | null>>({}); // Refs for sections
const provincesByAlphabets = computed(() => {
	return props.provinces.reduce(
		(acc, item) => {
			if (searchQuery.value !== '') {
				if (
					item.name
						.toLowerCase()
						.startsWith(searchQuery.value.toLocaleLowerCase())
				) {
					const firstChar = item.name[0]!;
					if (!acc[firstChar]) {
						acc[firstChar] = [];
					}
					acc[firstChar].push(item);
				}
			} else {
				const firstChar = item.name[0]!;
				if (!acc[firstChar]) {
					acc[firstChar] = [];
				}
				acc[firstChar].push(item);
			}
			return acc;
		},
		{} as Record<string, typeof props.provinces>,
	);
});

onMounted(() => {
	currentAlphabet.value = Object.keys(provincesByAlphabets.value)[0]!;
});

const emits = defineEmits(['close']);
</script>
<template>
	<FormField
		v-slot="{ handleChange }"
		:name="fieldName"
		class="w-full"
		:keep-value="true"
	>
		<FormItem>
			<FormControl class="w-full">
				<div
					class="flex flex-col py-md gap-3xl px-3xl h-[475px] relative provinces-container lg:px-sm lg:py-xs"
					:class="props.class"
				>
					<Input
						v-model="searchQuery"
						placeholder="Search"
						class="py-2xl bg-gray-100 border-none"
					>
						<template #leftIcon>
							<PhMagnifyingGlass />
						</template>
					</Input>
					<div class="overflow-auto no-scrollbar">
						<div
							v-for="(item, key) in provincesByAlphabets"
							:key="key"
							:ref="(el) => (provinceRefs[key] = el as HTMLElement | null)"
							class="flex flex-col gap-xxs flex-1 border-b border-gray-200"
						>
							<div class="py-xxs px-md">
								<p class="text-gray-400 text-md font-bold">{{ key }}</p>
							</div>
							<div
								v-for="(province, key2) in item"
								:key="key2"
								class="flex flex-col gap-md flex-1"
								@click="
									handleChange(province.code);

									emits('close');
								"
							>
								<div
									class="flex py-lg pr-8xl pl-md justify-between hover:cursor-pointer"
									:class="{ 'bg-gray-50': province.code === provinceCode }"
								>
									<p class="text-gray-900 text-md">{{ province.name }}</p>
									<PhCheck v-if="province.code === provinceCode" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormControl>
		</FormItem>
	</FormField>
</template>
