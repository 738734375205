<script setup lang="ts">
import {
	DropdownMenu,
	DropdownMenuTrigger,
	Button,
	DropdownMenuContent,
} from '@laam/ui/base';
import { useFieldValue, useField } from 'vee-validate';
import { getProvinceName } from '~/utils/checkout';
import ProvinceSelectorBody from './ProvinceSelectorBody.vue';
import { PhCaretDown } from '@phosphor-icons/vue';
const open = ref(false);
interface ProvinceDropdown {
	provinces: {
		name: string;
		code: string;
	}[];
	fieldName: string;
	label?: string;
}
const props = defineProps<ProvinceDropdown>();
const provinceCode = useFieldValue(props.fieldName) as Ref<string>;
const provinceErrors = useField(props.fieldName);
const close = () => {
	open.value = false;
	provinceErrors.setErrors('');
};
const provinceName = computed(() => {
	if (provinceCode.value === '') {
		return 'State/province';
	}
	return (
		getProvinceName(provinceCode.value, props.provinces)?.name ||
		'State/province'
	);
});
const emit = defineEmits(['focus', 'blur']);

const handleFocus = () => emit('focus');
const handleBlur = () => emit('blur');
</script>
<template>
	<DropdownMenu
		v-model:open="open"
		class="hover:cursor-pointer"
		@update:open="(isOpen) => (isOpen ? handleFocus() : handleBlur())"
	>
		<div class="flex flex-col gap-sm w-full">
			<div v-if="label" class="text-sm font-medium text-gray-700">
				{{ label }}
			</div>
			<DropdownMenuTrigger
				:as-child="true"
				class="hover:cursor-pointer"
				:class="{ dropdown: open }"
				@click="open = true"
			>
				<Button
					class="w-full"
					variant="dropdown"
					size="dropdown"
					:as-child="true"
				>
					<div class="flex items-center">
						<p class="text-gray-900 text-md w-full">{{ provinceName }}</p>
						<PhCaretDown />
					</div>
				</Button>
				<p v-if="provinceErrors.errorMessage" class="text-red-500 text-sm">
					{{ provinceErrors.errorMessage.value }}
				</p>
			</DropdownMenuTrigger>
			<DropdownMenuContent class="w-[352px] bg-white">
				<ProvinceSelectorBody
					:provinces="provinces"
					class="w-[352px]"
					:field-name="fieldName"
					:label="label"
					@close="close"
				/>
			</DropdownMenuContent>
		</div>
	</DropdownMenu>
</template>
