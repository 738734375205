<script setup lang="ts">
import { SheetTrigger, Sheet, SheetContent, SheetHeader } from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import countries from '~/utils/countries.json';
import { getProvinceName } from '~/utils/checkout';
import { useFieldValue, useField } from 'vee-validate';
import ProvinceDropdown from '../province-dropdown/ProvinceDropdown.vue';
import ProvinceSelectorBody from '../province-dropdown/ProvinceSelectorBody.vue';

const countryCode = useFieldValue('updateCountryTo') as Ref<string>;
const provinceCode = useFieldValue('updateProvinceTo') as Ref<string>;
const provinceErrors = useField('updateProvinceTo');
const open = ref(false);

const provinces = computed(() => {
	const country = countries[countryCode.value as keyof typeof countries];
	const zones = country && 'zones' in country ? country.zones : [];
	return zones;
});

const provinceName = computed(() => {
	if (provinceCode.value === '') {
		return 'State/province';
	}
	return (
		getProvinceName(provinceCode.value, provinces.value)?.name ||
		'State/province'
	);
});
const close = () => {
	open.value = false;
	provinceErrors.setErrors('');
};
</script>

<template>
	<Sheet v-if="isMobileView()" v-model:open="open" @update:open="open != open">
		<SheetTrigger :as-child="true" @click="open = true">
			<div
				class="flex py-lg px-2xl items-center rounded-medium border border-gray-300"
			>
				<p class="text-gray-900 text-md w-full">{{ provinceName }}</p>
				<PhCaretDown />
			</div>
		</SheetTrigger>
		<SheetContent side="bottom" class="h-[540px] rounded-t-xl">
			<SheetHeader class="border-none" :tabindex="0">
				State/Province
			</SheetHeader>
			<ProvinceSelectorBody
				:provinces="provinces"
				field-name="updateProvinceTo"
				@close="close"
			/>
		</SheetContent>
	</Sheet>
	<ProvinceDropdown
		v-else
		:provinces="provinces"
		field-name="updateProvinceTo"
	/>
</template>
