<script setup lang="ts">
const show = ref(true);
const handleClickOutside = () => {
	show.value = false;
	localStorage.setItem('showDeliveryOptionsTooltip', 'false');
};
onMounted(() => {
	if (localStorage.getItem('showDeliveryOptionsTooltip') === 'false') {
		show.value = false;
	}

	window.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
	window.removeEventListener('click', handleClickOutside);
});
</script>
<template>
	<div
		v-if="show"
		class="flex px-xl py-md rounded-medium border-gray-300 border bg-gray-100 top-[53px] lg:right-[30px] mx-auto absolute w-max left-0 right-0 lg:left-auto shadow-sm"
	>
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="absolute top-[-7px] right-[40px]"
		>
			<path
				d="M6 11.95L0.343146 6.2931L6 0.636243L11.6569 6.2931L6 11.95Z"
				fill="#F5F5F5"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.6569 6.2931L6 0.636243L0.343146 6.2931L1.05025 7.0002L6 2.05046L10.9497 7.0002L11.6569 6.2931Z"
				fill="#D9D9D9"
			/>
		</svg>

		<p class="text-black text-sm">Click here to select delivery location</p>
	</div>
</template>
