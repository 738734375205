<script setup lang="ts">
import { Input, FormField, FormItem, FormControl } from '@laam/ui/base';
import { PhCheck, PhMagnifyingGlass } from '@phosphor-icons/vue';
import { useVirtualList } from '@vueuse/core';
import { COUNTRY_CURRENCY_MAP, countriesCurrencyMap } from '~/utils/enums';
import { useFieldValue } from 'vee-validate';
import { useCountryCode } from '~/stores/country';
import { sortedCountries } from '../../utils/sortedCountriesList.ts';

interface SelectorBody {
	type: string;
	class?: string;
}
const props = defineProps<SelectorBody>();
const { countryCode } = storeToRefs(useCountryCode());
const code = useFieldValue(
	props.type === 'country' ? 'updateCountryTo' : 'updateCurrencyTo',
) as Ref<string>;

const searchQuery = ref('');

const filteredData = ref<
	(
		| (typeof sortedCountries)[number]
		| (typeof COUNTRY_CURRENCY_MAP)[keyof typeof COUNTRY_CURRENCY_MAP]
	)[]
>([]);

onMounted(() => {
	if (props.type === 'country') {
		filteredData.value = sortedCountries;
	} else {
		const values = Object.values(COUNTRY_CURRENCY_MAP);
		const updateCountryTo = useFieldValue('updateCountryTo') as Ref<string>;

		// We have to show native currency and update to country's currency
		// Check what currency the new country will support
		const newCurrency = countriesCurrencyMap[updateCountryTo.value] ?? 'usd'; // update to country's currency
		const availableCurrency = values.filter(
			(item) =>
				// this check is for native currency
				item.countryCode === countryCode.value ||
				// this check is for update to country's currency
				newCurrency === item.code,
		);
		filteredData.value = availableCurrency;
	}
});

const { list, containerProps, wrapperProps } = useVirtualList(filteredData, {
	itemHeight: 35,
});

const emits = defineEmits(['close']);

const scrollToTop = () => {
	const contentElement = document.getElementById('listContainer');
	if (contentElement) {
		contentElement.scrollTop = 0;
	}
};

watch(searchQuery, (query) => {
	scrollToTop();
	filteredData.value = sortedCountries.filter((item) => {
		return item.name.toLowerCase().startsWith(query.toLowerCase());
	});
});
</script>
<template>
	<FormField
		v-slot="{ handleChange }"
		:name="type === 'country' ? 'updateCountryTo' : 'updateCurrencyTo'"
		class="w-full"
	>
		<FormItem>
			<FormControl>
				<div
					class="flex flex-col py-md gap-3xl px-3xl lg:px-sm lg:py-xs"
					:class="props.class"
				>
					<Input
						v-if="type === 'country'"
						id="searchInput"
						v-model="searchQuery"
						placeholder="Search"
						class="py-2xl bg-gray-100 border-none"
						data-testid="country-selector-input"
					>
						<template #leftIcon>
							<PhMagnifyingGlass />
						</template>
					</Input>
					<div
						v-if="filteredData.length"
						id="listContainer"
						v-bind="containerProps"
						class="h-[450px]"
						@input.prevent
					>
						<div v-bind="wrapperProps">
							<div
								v-for="(item, index) in list"
								:key="index"
								:value="item.data"
								class="cursor-pointer"
								@input.prevent
							>
								<div
									class="flex px-sm py-[1px] w-full"
									:class="{
										'bg-gray-50': item.data.code === code,
									}"
									data-testid="country-selector-option"
									@click="
										handleChange(item.data.code);
										emits('close');
									"
								>
									<div
										class="py-lg pr-3xl px-md gap-lg flex items-center flex-1 justify-between border-b border-gray-200"
									>
										<div class="flex items-center flex-1 gap-md">
											<NuxtImg
												:src="item.data.flag"
												alt="country flag"
												class="w-5xl h-5xl !max-w-[20px]"
											></NuxtImg>
											<p class="text-gray-900 text-md">
												{{ item.data.name }}
											</p>
										</div>
										<PhCheck
											v-if="item.data.code === code"
											class="w-5xl h-5xl"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormControl>
		</FormItem>
	</FormField>
</template>
